<template>
  <div v-if="this.displayMap" class="grid grid-cols-10 gap-4 m-4">
    <div class="lg:col-span-3 col-span-10">
      <h1 class="font-noodle text-secondary text-4xl">Filter By State</h1>
      <SelectBox
        :options="this.states"
        @change="this.stateFilter()"
        id="stateDropdown"
      >
      </SelectBox>
    </div>
    <div class="lg:col-span-7 col-span-10">
      <h2 class="font-noodle text-primary text-lg leading-10">
        Locate playa bowls near you
      </h2>
      <div class="grid grid-cols-3 gap-3">
        <div class="col-span-2 relative">
          <InputBox
            placeholder="Enter Location"
            id="locationSearch"
            v-on:keyup.enter="this.getCoordinates()"
          ></InputBox>
          <img
            src="/geolocate.svg"
            class="h-8 w-8 absolute top-4 right-2"
            alt=""
            @click="this.userLocationSearch()"
          />
        </div>
        <div class="col-span-1">
          <SelectBox :options="rangeOptions" id="range"></SelectBox>
        </div>
      </div>
    </div>
    <div
      class="lg:col-span-3 col-span-10"
      style="height: 550px; overflow-y: scroll"
      id="locationContainer"
    >
      <div v-if="!this.loaded">
        <Loading></Loading>
      </div>
      <div v-if="!this.filteredLocations.length && this.loaded">
        <p class="font-ddin">
          There are no Playabowls locations in this location... yet!
        </p>
      </div>
      <div v-for="location in this.filteredLocations" :key="location.id">
        <LocationCard :location="location"></LocationCard>
        <div class="border-solid border-primary border-t"></div>
      </div>
    </div>
    <div class="lg:col-span-7 col-span-10 row-span-3">
      <GMapMap
        style="width: 100%; height: 550px"
        ref="map"
        :center="this.center"
        :zoom="4"
        map-type-id="roadmap"
        autobindAllEvents: false,
        :options="{
          styles: this.mapStyle,
           'maxZoom': 15
        }"
      >
        <GMapCluster :zoomOnClick="true">
          <GMapMarker
            v-for="location in this.allLocations"
            :key="location.id"
            :position="{ lat: location.latitude, lng: location.longitude }"
            :clickable="true"
            @click="this.openMarker(location.name)"
            @closeclick="this.openMarker(null)"
            :icon="{
              url: 'playa-bowls-marker.webp',
              scaledSize: { width: 60, height: 60 },
            }"
          >
            <GMapInfoWindow
              class="w-[34rem] h-[15rem]"
              :closeclick="true"
              @closeclick="this.openMarker(null)"
              :opened="this.openedMarkerId === location.name"
            >
              <LocationCard :showImage="this.openedMarkerId === location.name" :location="location"></LocationCard>
            </GMapInfoWindow>
          </GMapMarker>
          <GMapMarker
            ref="searchMarker"
            v-if="this.searchLocation.lat"
            :position="{
              lat: this.searchLocation.lat,
              lng: this.searchLocation.lng,
            }"
          >
          </GMapMarker>
        </GMapCluster>
      </GMapMap>
    </div>
  </div>
  <div v-if="!this.displayMap" class="grid grid-cols-2 gap-4 m-4">
    <div class="lg:col-span-1 col-span-2">
      <h1 class="font-noodle text-secondary text-4xl">Filter By State</h1>
      <SelectBox
        :options="this.states"
        @change="this.stateFilter()"
        id="stateDropdown"
      >
      </SelectBox>
    </div>
    <div class="lg:col-span-1 col-span-2">
      <h2 class="font-noodle text-primary text-lg leading-10">
        Locate playa bowls near you
      </h2>
      <div class="grid grid-cols-3 gap-3">
        <div class="col-span-2 relative">
          <InputBox
            placeholder="Enter Location"
            id="locationSearch"
            v-on:keyup.enter="this.getCoordinates()"
          ></InputBox>
          <img
            src="/geolocate.svg"
            class="h-8 w-8 absolute top-4 right-2"
            alt=""
            @click="this.userLocationSearch()"
          />
        </div>
        <div class="col-span-1">
          <SelectBox :options="rangeOptions" id="range"></SelectBox>
        </div>
      </div>
    </div>
    <div
      class="col-span-2"
      style="height: 550px; overflow-y: scroll"
      id="locationContainer"
    >
      <div v-for="location in this.filteredLocations" :key="location.id">
        <LocationCard :location="location"></LocationCard>
        <div class="border-solid border-primary border-t"></div>
      </div>
    </div>
  </div>
  <div class="hidden" id="dummyMap"></div>

  <router-view></router-view>
</template>

<script>
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://vuejs.org/api/sfc-script-setup.html#script-setup
import SelectBox from "./components/SelectBox.vue";
import InputBox from "./components/InputBox.vue";
import LocationCard from "./components/LocationCard.vue";
import Loading from "./components/Loading.vue";
import axios from "axios";
import { DOMDirectiveTransforms } from "@vue/compiler-dom";
import mapStyle from "./assets/MapStyle.json";
import locations from "./assets/locations.json";
import { useRoute } from "vue-router";
import HoneyBadger from '@honeybadger-io/vue';

export default {
  components: { SelectBox, InputBox, LocationCard, Loading },
  data() {
    return {
      loaded: false,
      openedMarkerId: null,
      range: "",
      searchLocation: {
        lat: null,
        lng: null,
      },
      center: {
        lat: 40.682947,
        lng: -74.044502,
      },
      states: [],
      allLocations: locations,
      mapStyle: mapStyle,
      filteredLocations: [],
      map: null,
      bounds: null,
      rangeOptions: {
        10: "10 mi",
        25: "25 mi",
        50: "50 mi",
        100: "100 mi",
        150: "150 mi",
      },
    };
  },

  computed: {
    displayMap() {
      const route = useRoute();
      return route.query.map ? true : false;
    },
  },

  beforeMount() {
    this.allLocations = locations;
  },

  mounted() {
    const date = new Date();

    axios
      .get(
        import.meta.env.VITE_API_ROOT + "/locations?timestamp=" + date.setMinutes(0, 0, 0)
      )
      .then((response) => {
        this.allLocations = response.data;
        this.filteredLocations = this.allLocations;
        this.loaded = true;
        this.generateStates();
        this.map = this.displayMap ? this.displayMap : this.dummyMap;
      })
      .catch(() => {
        this.allLocations = locations;
        this.filteredLocations = this.allLocations;
        this.loaded = true;
        this.generateStates();
        this.map = this.displayMap ? this.displayMap : this.dummyMap;
      });
  },

  methods: {
    generateStates() {
      let statesCount = [];
      this.allLocations.forEach((location) => {
        if (location.state.toUpperCase() in statesCount) {
          ++statesCount[location.state.toUpperCase()];
        } else {
          statesCount[location.state.toUpperCase()] = 1;
        }
      });

      let totalCount = 0;

      for (let i = 0, keys = Object.keys(statesCount), ii = keys.length; i < ii; i++) {
        this.states[keys[i]] = keys[i] + "(" + statesCount[keys[i]] + ")";

        totalCount += statesCount[keys[i]];
      }

      if (totalCount != this.allLocations.length) {
        throw new Error('Locations by state improperly parsed. '+totalCount+': Total. '+this.allLocations.length+": by state");
      }

      this.states = Object.keys(this.states)
        .sort()
        .reduce((result, key) => {
          result[key] = this.states[key];
          return result;
        }, {});

      this.states = Object.assign({ All: "All" }, this.states);

      return;
    },

    stateFilter() {
      document.querySelector("#locationContainer").scrollTop = 0;

      this.searchLocation.lat = null;
      this.searchLocation.lng = null;
      const state = document.querySelector("#stateDropdown").value;

      this.filteredLocations = [];

      if (state === "All") {
        this.filteredLocations = this.allLocations;
      } else {
        this.filteredLocations = this.allLocations.filter((location) => {
          return location.state.toUpperCase() == state.toUpperCase();
        });
      }

      this.setBounds();
    },

    locationFilter() {
      document.querySelector("#locationContainer").scrollTop = 0;

      this.range = document.querySelector("#range").value;

      this.filteredLocations = [];

      this.filteredLocations = this.allLocations.filter((location) => {
        return this.isWithinRange(
          location.latitude,
          location.longitude,
          this.searchLocation.lat,
          this.searchLocation.lng,
          this.range
        );
      });

      const compare = (a, b) => {
        if (
          this.distance(
            a.latitude,
            a.longitude,
            this.searchLocation.lat,
            this.searchLocation.lng
          ) <
          this.distance(
            b.latitude,
            b.longitude,
            this.searchLocation.lat,
            this.searchLocation.lng
          )
        ) {
          return -1;
        }
        return 1;
      };

      this.filteredLocations.sort(compare);

      this.setBounds();
    },

    isWithinRange(latA, lngA, latB, lngB, range) {
      const distance = this.distance(latA, lngA, latB, lngB);

      return distance < range;
    },

    distance(latA, lngA, latB, lngB) {
      latA = (latA * Math.PI) / 180;
      lngA = (lngA * Math.PI) / 180;
      latB = (latB * Math.PI) / 180;
      lngB = (lngB * Math.PI) / 180;

      const deltaLat = latB - latA;
      const deltaLng = lngB - lngA;

      const a =
        Math.pow(Math.sin(deltaLat / 2), 2) +
        Math.cos(latA) * Math.cos(latB) * Math.pow(Math.sin(deltaLng / 2), 2);

      const c = 2 * Math.asin(Math.sqrt(a));

      return c * 6371;
    },

    setBounds() {
      this.map = this.displayMap ? this.$refs.map : this.dummyMap;

      this.bounds = new google.maps.LatLngBounds();

      this.filteredLocations.forEach((location) => {
        const position = new google.maps.LatLng(
          location.latitude,
          location.longitude
        );
        this.bounds.extend(position);
      });

      if (this.searchLocation.lat) {
        const userPosition = new google.maps.LatLng(
          this.searchLocation.lat,
          this.searchLocation.lng
        );
        this.bounds.extend(userPosition);
      }

      this.map.fitBounds(this.bounds, 50);
    },

    openMarker(id) {
      this.openedMarkerId = id;
    },

    getCoordinates() {
      const request = {
        query: document.querySelector("#locationSearch").value,
      };

    axios.get(import.meta.env.VITE_API_ROOT + '/geocode' + '?search=' + document.querySelector("#locationSearch").value)
      .then((response) => {
        this.searchLocation = response.data
        this.locationFilter();
      })
      .catch((e) => {
        console.log(e)
      });
    },

    userLocationSearch() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.searchLocation.lat = position.coords.latitude;
          this.searchLocation.lng = position.coords.longitude;
          this.locationFilter();
        });
      }
    },
  },
};
</script>
