<script setup>
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://vuejs.org/api/sfc-script-setup.html#script-setup
const props = defineProps({
    showImage: Boolean,
    location,
});
</script>

<script>
import OrderNowButton from "./OrderNowButton.vue";

export default {
    components: { OrderNowButton },

    methods: {
        getImage(location) {
            return location.image_url
                ? location.image_url
                : "/playa-bowls-marker.webp";
        },
    },
};
</script>

<template>
    <div class="grid grid-cols-2 lg:grid-cols-4 my-4 py-4">
        <div v-if="showImage" class="col-span-1 row-span-6">
            <img :src="getImage(location)" :alt="location.name" style="margin-right: 16px"
                class="rounded-full w-32 h-32 m-4" />
        </div>
        <div class="col-span-3 row-span-3 ml-2">
            <a :href="location.link" target="_parent">
                <p class="font-noodle text-lg pl-2 pt-2">{{ location.name }}</p>
            </a>
            <div class="font-ddin text-m ml-2">
                <p>{{ location.address }},</p>
                <p>{{ location.city }}, {{ location.state }} {{ location.zip_code }}</p>
                <p class="font-ddinbold">
                    {{ location.location_comingsoon ? "OPENING SOON!" : location.timing }}
                </p>
            </div>
            <div class="flex gap-1 text-m py-4 ml-2 text-center font-noodle">
                <div>
                    <img src="/store.svg" alt="store" class="inline mr-1" />
                    <a :href="location.link" class="text-secondary underline hover:no-underline uppercase" target="_parent">
                        Store Profile
                    </a>
                </div>
                <div class="text-charcoal">|</div>
                <div>
                    <img src="/phone.svg" alt="phone" class="inline mx-1" />
                    <a :href="'tel:' + location.contact_number" class="text-secondary underline hover:no-underline"
                        target="_parent">
                        {{ location.contact_number }}
                    </a>
                </div>
                <div>
                    <p class="text-charcoal">|</p>
                </div>
                <div>
                    <img src="/location.svg" alt="location" class="inline mr-1" />
                    <a class="text-secondary underline hover:no-underline uppercase" :href="'https://maps.google.com/maps?saddr=&daddr=' +
                        location.latitude +
                        ',' +
                        location.longitude
                        " target="_parent">
                        Get Directions
                    </a>
                </div>
            </div>

            <OrderNowButton :orderLink="location.order_now_link" :locationName="location.name"></OrderNowButton>
        </div>
    </div>
</template>
