<script setup>
// Check out https://vuejs.org/api/sfc-script-setup.html#script-setup
const props = defineProps({
    orderLink: String,
    locationName: String
})

const showLink = props.orderLink.includes('tapmango');

</script>

<template>
    <a
        v-if="showLink"
        :href="orderLink"
        class="text-white inline-block order-link px-16 pt-4 pb-3 text-3xl font-noodle leading-7 text-center uppercase bg-transparent bg-no-repeat cursor-pointer md:text-3xl md:leading-7 box-border"
        target="_parent"
        :id="'tap-mango-'+encodeURIComponent(locationName.replaceAll(' ', '-'))"
    >
        Order Now
    </a>
</template>

<style>
.order-link {
    background: url(@/assets/images/link-bg3-50.png) no-repeat 50%;
    color: #ffffff;
}

.order-link:hover {
    background: url(@/assets/images/link-bg3-hover-50.png) no-repeat 50%;
}
</style>
