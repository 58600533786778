<template>
    <content-loader>
        <rect x="154" y="44" rx="3" ry="3" width="109" height="7" /> 
        <rect x="160" y="62" rx="3" ry="3" width="79" height="9" /> 
        <rect x="156" y="81" rx="3" ry="3" width="410" height="6" /> 
        <rect x="160" y="99" rx="3" ry="3" width="380" height="6" /> 
        <rect x="154" y="128" rx="3" ry="3" width="233" height="8" /> 
        <circle cx="58" cy="87" r="54" /> 
        <rect x="150" y="145" rx="0" ry="0" width="218" height="77" /> 
        <rect x="313" y="142" rx="0" ry="0" width="0" height="2" /> 
        <rect x="312" y="147" rx="0" ry="0" width="65" height="1" />
    </content-loader>
    <content-loader>
        <rect x="154" y="44" rx="3" ry="3" width="109" height="7" /> 
        <rect x="160" y="62" rx="3" ry="3" width="79" height="9" /> 
        <rect x="156" y="81" rx="3" ry="3" width="410" height="6" /> 
        <rect x="160" y="99" rx="3" ry="3" width="380" height="6" /> 
        <rect x="154" y="128" rx="3" ry="3" width="233" height="8" /> 
        <circle cx="58" cy="87" r="54" /> 
        <rect x="150" y="145" rx="0" ry="0" width="218" height="77" /> 
        <rect x="313" y="142" rx="0" ry="0" width="0" height="2" /> 
        <rect x="312" y="147" rx="0" ry="0" width="65" height="1" />
    </content-loader>
    <content-loader>
        <rect x="154" y="44" rx="3" ry="3" width="109" height="7" /> 
        <rect x="160" y="62" rx="3" ry="3" width="79" height="9" /> 
        <rect x="156" y="81" rx="3" ry="3" width="410" height="6" /> 
        <rect x="160" y="99" rx="3" ry="3" width="380" height="6" /> 
        <rect x="154" y="128" rx="3" ry="3" width="233" height="8" /> 
        <circle cx="58" cy="87" r="54" /> 
        <rect x="150" y="145" rx="0" ry="0" width="218" height="77" /> 
        <rect x="313" y="142" rx="0" ry="0" width="0" height="2" /> 
        <rect x="312" y="147" rx="0" ry="0" width="65" height="1" />
    </content-loader>
</template>

<script>
    import { ContentLoader } from 'vue-content-loader';

    export default {
        components: {
            ContentLoader
        },
    }
</script>