import { createApp } from 'vue'
import { createRouter, createWebHistory } from "vue-router";
import App from './App.vue'
import './index.css'
import VueGoogleMaps from 'vue-google-maps-community-fork'
import HoneybadgerVue from '@honeybadger-io/vue';

const routes = [];
const router = createRouter({
    history: createWebHistory(),
    routes
});

const app = createApp(App);

const honeybadgerConfig = {
    apiKey: import.meta.env.VITE_HONEYBADGER_API_KEY,
    environment: "production",
}

app.use(HoneybadgerVue, honeybadgerConfig)
app.use(router);
app.use(VueGoogleMaps, {
    load: {
        key: import.meta.env.VITE_GOOGLE_API_KEY,
        libraries: 'places',
        v: "3",
    },
}).mount('#app')
